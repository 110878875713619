@keyframes zoom {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.5);
    }
  }
  
  .zoom-animation {
    transform: scale(1);
    animation: zoom 35s infinite;
  }
  
  
 
  