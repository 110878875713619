@tailwind base;
@tailwind components;
@tailwind utilities;


/* Customize the scrollbar itself */
.custom-scrollbar::-webkit-scrollbar {
    width: 12px; /* Set the width of the scrollbar */
    height: 12px; /* Set the height for horizontal scrollbar */
  }
  
  /* Customize the scrollbar track (the part the thumb slides on) */
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light grey background */
    border-radius: 10px; /* Rounded corners for the track */
  }
  
  /* Customize the scrollbar thumb (the draggable part of the scrollbar) */
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #888; /* Darker color for the thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
    border: 3px solid #f1f1f1; /* Optional: Border to give the thumb some separation from the track */
  }
  
  /* Change the scrollbar thumb color when hovering */
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darker thumb color on hover */
  }
  
  /* Customize the scrollbar corner (bottom-right corner) */
  .custom-scrollbar::-webkit-scrollbar-corner {
    background: #f1f1f1;
  }
  
  /* For Firefox */
  .custom-scrollbar {
    scrollbar-width: thin; /* Thin scrollbar */
    scrollbar-color: #afa1b5 #f1f1f1; /* thumb color and track color */
  }


  .masonry {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;
  }

  /* Hide scrollbar for Chrome, Safari and Edge */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.scrollbar-hidden {
  scrollbar-width: none;
}

.no-rounded * {
  border-radius: 0 !important;
}

.text-custom-green {
  color: #267047;
}


.clip-path-custom {
  clip-path: polygon(0% 20%, 15% 0%, 85% 0%, 100% 20%, 100% 100%, 0% 100%);
}
